import React from 'react';
import SEO from '../SEO/SEO';
import { Link } from '@reach/router';
import './Page404.scss';

class Page404 extends React.Component {

    render() {

        return (
            <section id="page404">
                <SEO />
                <h1>404</h1>
                <div id="page404-message">Taka strona nie istnieje.</div>
                <div>
                    <Link to={'/'}>Powrót do strony głównej.</Link>
                </div>
            </section>
        );
    }
}

export default  Page404;