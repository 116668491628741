import React from 'react';
import Page404 from '../components/Page404';

class Error404 extends React.Component {

    render() {

        return (
            <Page404 />
        );
    }
}

export default  Error404;